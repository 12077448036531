<template>
  <div class="home">
    <banners
      v-if="pageContent?.currentLocaleData?.banners || pageContent?.defaultLocaleData?.banners"
      :items="pageContent?.currentLocaleData?.banners || pageContent?.defaultLocaleData?.banners"
      :banner-loyalty="pageContent?.currentLocaleData?.bannerLoyalty || pageContent?.defaultLocaleData?.bannerLoyalty"
    />

    <div
      v-if="pageContent?.currentLocaleData?.categories || pageContent?.defaultLocaleData?.categories"
      class="card-category__container"
      :class="cardsModifier"
    >
      <card-category
        v-for="(item, itemIndex) in pageContent?.currentLocaleData?.categories ||
        pageContent?.defaultLocaleData?.categories"
        :key="itemIndex"
        :mod="itemIndex + 1"
        v-bind="item"
      />
    </div>
    
    <group-aero
      v-if="pageContent?.currentLocaleData?.aeroGroup?.display && aeroCategory"
      show-all-btn
      show-arrows
      :category="aeroCategory"
      :current-locale-content="pageContent?.currentLocaleData?.aeroGroup"
      :default-locale-content="pageContent?.defaultLocaleData?.aeroGroup"
    />

    <template v-for="collection in gameCollectionsList">
      <group-games v-if="collection" :key="collection.id" show-all-btn show-arrows :category="collection" />
    </template>
    
    <group-providers show-arrows show-all-btn />

    <activity-board
      v-if="activityBoardContent.showBlock && activityBoardContent.boards.length"
      v-bind="activityBoardContent"
    />

    <group-promotions />

    <atomic-seo-text
      v-if="pageContent?.currentLocaleData?.pageMeta?.seoText"
      v-bind="pageContent.currentLocaleData.pageMeta.seoText"
    />
  </div>
</template>

<script setup lang="ts">
  import type { IHomePage } from '~/types';
  import type { ICollection } from '@skeleton/core/types';
  const { getContent } = useProjectMethods();

  const contentParams = {
    contentKey: 'homePageContent',
    contentRoute: ['pages', 'home'],
    isPage: true,
  };
  const { getContentData } = useContentLogic<IHomePage>(contentParams);
  const { data: pageContent } = await useLazyAsyncData(getContentData);

  const { getCollectionsList } = useGamesStore();
  const { data: gameCollections } = await useLazyAsyncData(() => getCollectionsList(), { server: false });

  const aeroCategory = computed(() => {
    return gameCollections.value?.find(
      collection => collection.identity === pageContent.value?.currentLocaleData?.aeroGroup?.collectionIdentity
    );
  });

  const targetGameCollections = computed(() => {
    return (
      getContent(pageContent.value?.currentLocaleData, pageContent.value?.defaultLocaleData, 'gameCollections')?.map(
        (item: ICollection) => item.identity
      ) || []
    );
  });

  const gameCollectionsList = computed(() =>
    gameCollections.value
      ?.filter(collection => targetGameCollections.value.includes(collection.identity))
      ?.sort((a, b) => {
        return targetGameCollections.value?.indexOf(a.identity) - targetGameCollections.value?.indexOf(b.identity);
      })
  );

  const cardsModifier = computed(() => {
    const length =
      Object.keys(getContent(pageContent.value?.currentLocaleData, pageContent.value?.defaultLocaleData, 'categories'))
        ?.length || 0;
    return length ? `has-${length}-cards` : '';
  });
  
  const activityBoardContent = computed(() => {
    const currentLocaleBoardContent = pageContent.value?.currentLocaleData?.activityBoard;
    const defaultLocaleBoardContent = pageContent.value?.defaultLocaleData?.activityBoard;
    return {
      showBlock: currentLocaleBoardContent?.showBlock,
      title: currentLocaleBoardContent?.title || defaultLocaleBoardContent?.title,
      icon: currentLocaleBoardContent?.icon || defaultLocaleBoardContent?.icon,
      columns: currentLocaleBoardContent?.columns || defaultLocaleBoardContent?.columns,
      boards: currentLocaleBoardContent?.boards?.length
        ? currentLocaleBoardContent.boards
        : defaultLocaleBoardContent?.boards || [],
    };
  });

  onMounted(async () => {
    await getCollectionsList();
  });
</script>

<style src="@skeleton/assets/styles/pages/index.scss" lang="scss" />
