
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93CTaibdQ8tHMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/[...slug].vue?macro=true";
import { default as autologinQyDns6PY4jMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/auth/autologin.vue?macro=true";
import { default as callbackmlSW1rsOpgMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/auth/callback.vue?macro=true";
import { default as betting3ngCPWmP7jMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/betting.vue?macro=true";
import { default as _91pageIdentity_93IGvzImlwfIMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/bonus/[pageIdentity].vue?macro=true";
import { default as _91categoryIdentity_931bQUAoCKpNMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/categories/[categoryIdentity].vue?macro=true";
import { default as categoriesdhwYxfOEBdMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/categories.vue?macro=true";
import { default as contactKe9DAx89hPMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/contact.vue?macro=true";
import { default as favorites_46clientxQPQsumfwWMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/favorites.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/platform/customer-frontends/fpscasino/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91id_93UHeRhUoyQOMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/games/[id].vue?macro=true";
import { default as indexy1uw6NuBnAMeta } from "/builds/platform/customer-frontends/fpscasino/pages/index.vue?macro=true";
import { default as loyaltyUbthfBel4LMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/loyalty.vue?macro=true";
import { default as mainIOWwjciJemMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/main.vue?macro=true";
import { default as bonusesDoR49en5IlMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/bonuses.vue?macro=true";
import { default as documentslisFvhksnRMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/documents.vue?macro=true";
import { default as history7GiojCTLN6Meta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/history.vue?macro=true";
import { default as infogrQb65HS6hMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/info.vue?macro=true";
import { default as limits6HluZRbMkzMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/limits.vue?macro=true";
import { default as notificationsyFzuuCUSI5Meta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/notifications.vue?macro=true";
import { default as securityon6P6RcN75Meta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/security.vue?macro=true";
import { default as verificationgrjUF7rlkQMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/verification.vue?macro=true";
import { default as walletFep9eXjJHHMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/wallet.vue?macro=true";
import { default as profile_46clienteF7ohpaUS0Meta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile.client.vue?macro=true";
import { default as providersxVH8gTOPtLMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/providers.vue?macro=true";
import { default as _91pageIdentity_93xtuAnddAq0Meta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/questions/[pageIdentity].vue?macro=true";
import { default as questionsRzuKv9disfMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/questions.vue?macro=true";
import { default as recently_45played_46clientXnw2hyDWMEMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/recently-played.client.vue?macro=true";
import { default as _91pageIdentity_93T0D1riAKZxMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/static/[pageIdentity].vue?macro=true";
import { default as _91identity_935hORtevVjDMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/tournaments/[identity].vue?macro=true";
import { default as indexsWltmqaxEBMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/tournaments/index.vue?macro=true";
import { default as welcome_45packagemDh2sXQ9DcMeta } from "/builds/platform/customer-frontends/fpscasino/skeleton/pages/welcome-package.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/[...slug].vue")
  },
  {
    name: "auth-autologin",
    path: "/auth/autologin",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/auth/autologin.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/auth/callback.vue")
  },
  {
    name: "betting",
    path: "/betting",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/betting.vue")
  },
  {
    name: "bonus-pageIdentity",
    path: "/bonus/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/bonus/[pageIdentity].vue")
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/categories.vue"),
    children: [
  {
    name: "categories-categoryIdentity",
    path: ":categoryIdentity()",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/categories/[categoryIdentity].vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/contact.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/favorites.client.vue"))
  },
  {
    name: "games-id",
    path: "/games/:id()",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/games/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/platform/customer-frontends/fpscasino/pages/index.vue")
  },
  {
    name: "loyalty",
    path: "/loyalty",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/loyalty.vue")
  },
  {
    name: "main",
    path: "/main",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/main.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile.client.vue")),
    children: [
  {
    name: "profile-bonuses",
    path: "bonuses",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/bonuses.vue")
  },
  {
    name: "profile-documents",
    path: "documents",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/documents.vue")
  },
  {
    name: "profile-history",
    path: "history",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/history.vue")
  },
  {
    name: "profile-info",
    path: "info",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/info.vue")
  },
  {
    name: "profile-limits",
    path: "limits",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/limits.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/notifications.vue")
  },
  {
    name: "profile-security",
    path: "security",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/security.vue")
  },
  {
    name: "profile-verification",
    path: "verification",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/verification.vue")
  },
  {
    name: "profile-wallet",
    path: "wallet",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/profile/wallet.vue")
  }
]
  },
  {
    name: "providers",
    path: "/providers",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/providers.vue")
  },
  {
    name: "questions",
    path: "/questions",
    meta: questionsRzuKv9disfMeta || {},
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/questions.vue"),
    children: [
  {
    name: "questions-pageIdentity",
    path: ":pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/questions/[pageIdentity].vue")
  }
]
  },
  {
    name: "recently-played",
    path: "/recently-played",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/recently-played.client.vue"))
  },
  {
    name: "static-pageIdentity",
    path: "/static/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/static/[pageIdentity].vue")
  },
  {
    name: "tournaments-identity",
    path: "/tournaments/:identity()",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/tournaments/[identity].vue")
  },
  {
    name: "tournaments",
    path: "/tournaments",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/tournaments/index.vue")
  },
  {
    name: "welcome-package",
    path: "/welcome-package",
    component: () => import("/builds/platform/customer-frontends/fpscasino/skeleton/pages/welcome-package.vue")
  }
]